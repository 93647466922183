import SubPageBanner from "./../../components/sub_banner";
import Big14 from "./../../assets/images/versitileservice.jpg";
import WhyChoose from "./../../components/why_choose";

const Services = () => {
  return (
    <>
      <SubPageBanner name="Services" page_name="services"/>

      <div className="container-fluid service py-5">
        <div className="container py-5">
          <div
            className="text-center mx-auto pb-5 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <h4 className="text-primary">We provide</h4>
            <h1 className="display-4 mb-4">
              Business Process Outsourcing (BPO) Across Industries
            </h1>
            <p className="mb-0">
              Agile Beyond offers specialized BPO services designed to optimize
              operations, enhance efficiency, and drive profitability across a
              variety of industries. While we have deep expertise in the
              logistics and freight management sectors, our innovative platform
              and comprehensive solutions are adaptable to meet the unique
              demands of multiple markets.{" "}
            </p>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-md-6 col-lg-6 col-xl-4 wow fadeInUp"
              data-wow-delay="0.2s"
              style={{ minHeight: "270px" }}
            >
              <div className="service-item">
                {/* <!-- <div className="service-img">
                                <img src="img/blog-1.png" className="img-fluid rounded-top w-100" alt="">
                                <div className="service-icon p-3">
                                    <i className="fa fa-users fa-2x"></i>
                                </div>
                            </div> --> */}
                <div className="service-content p-4">
                  <div className="service-content-inner">
                    <a href="#" className="d-inline-block h4 mb-4">
                      Comprehensive Back-Office Solutions
                    </a>
                    <p className="mb-4">
                      Our back-office services are tailored to automate
                      essential processes, reducing inefficiencies and
                      optimizing workflows. By streamlining operations, we help
                      logistics companies save time, cut costs, and increase
                      profitability.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-6 col-xl-4 wow fadeInUp"
              data-wow-delay="0.4s"
              style={{ minHeight: "270px" }}
            >
              <div className="service-item">
                {/* <!-- <div className="service-img">
                                <img src="img/blog-2.png" className="img-fluid rounded-top w-100" alt="">
                                <div className="service-icon p-3">
                                    <i className="fa fa-hospital fa-2x"></i>
                                </div>
                            </div> --> */}
                <div className="service-content p-4">
                  <div className="service-content-inner">
                    <a href="#" className="d-inline-block h4 mb-4">
                      Pricing Desk (Rate Management/ Quotation Desk/Pricing Desk
                      Analytics)
                    </a>
                    <p className="mb-4">
                      Specifically designed for freight management, our pricing
                      desk provides accurate and competitive freight rates
                      within a global timeframe of 1-4 hours. Our service is
                      supported by around-the-clock operations and advanced data
                      analytics.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-6 col-xl-4 wow fadeInUp"
              data-wow-delay="0.8s"
              style={{ minHeight: "270px" }}
            >
              <div className="service-item">
                <div className="service-content p-4">
                  <div className="service-content-inner">
                    <a href="#" className="d-inline-block h4 mb-4">
                      Personalized Analytics and Reporting
                    </a>
                    <p className="mb-4">
                      Our advanced analytics tools deliver in-depth insights
                      into logistics operations, allowing businesses to uncover
                      trends and optimize their processes effectively.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-6 col-xl-4 wow fadeInUp"
              data-wow-delay="0.8s"
              style={{ minHeight: "270px" }}
            >
              <div className="service-item">
                {/* <!-- <div className="service-img">
                                <img src="img/blog-4.png" className="img-fluid rounded-top w-100" alt="">
                                <div className="service-icon p-3">
                                    <i className="fa fa-home fa-2x"></i>
                                </div>
                            </div> --> */}
                <div className="service-content p-4">
                  <div className="service-content-inner">
                    <a href="#" className="d-inline-block h4 mb-4">
                      Customer Dashboard Services
                    </a>
                    <p className="mb-4">
                      We provide customizable dashboards that can be tailored to
                      the specific needs of logistics companies, offering
                      valuable insights that support data-driven
                      decision-making.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-md-6 col-lg-6 col-xl-4 wow fadeInUp"
              data-wow-delay="0.6s"
              style={{ minHeight: "270px" }}
            >
              <div className="service-item">
                {/* <!-- <div className="service-img">
                                <img src="img/blog-3.png" className="img-fluid rounded-top w-100" alt="">
                                <div className="service-icon p-3">
                                    <i className="fa fa-car fa-2x"></i>
                                </div>
                            </div> --> */}
                <div className="service-content p-4">
                  <div className="service-content-inner">
                    <a href="#" className="d-inline-block h4 mb-4">
                      On-time Tracking
                    </a>
                    <p className="mb-4">
                      Our real-time tracking feature offers full visibility into
                      logistics operations, enabling proactive management and
                      ensuring smooth, uninterrupted service delivery.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-6 col-xl-4 wow fadeInUp"
              data-wow-delay="0.8s"
              style={{ minHeight: "270px" }}
            >
              <div className="service-item">
                <div className="service-content p-4">
                  <div className="service-content-inner">
                    <a href="#" className="d-inline-block h4 mb-4">
                      Proactive Customer Service Support
                    </a>
                    <p className="mb-4">
                      Our advanced analytics tools deliver in-depth insights
                      into logistics operations, allowing businesses to uncover
                      trends and optimize their processes effectively.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-12 text-center wow fadeInUp"
              data-wow-delay="0.2s"
            >
              <a
                className="btn btn-primary rounded-pill py-3 px-5"
                href="/pricing"
              >
                See Pricing
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid faq-section bg-light py-5">
        <div className="container py-5">
          <div className="row g-5">
            <div className="mb-5">
              <h1 className="display-4 mb-0">
                Versatile BPO Solutions for Other Industries
              </h1>
            </div>
            <div
              className="col-xl-6 col-lg-6 col-md-6 col-sm-6 wow fadeInLeft"
              data-wow-delay="0.2s"
            >
              <div className="h-100">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button border-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Retail and E-commerce
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show active"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body rounded">
                        Streamline your supply chain, enhance customer support,
                        and manage inventory with our tailored back-office
                        services.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Healthcare and Pharmaceuticals
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Improve patient care and streamline administrative
                        processes with our specialized healthcare BPO solutions.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Financial Services
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Optimize your financial operations, including
                        accounting, HR management, payroll, and compliance, with our expert BPO
                        services.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Telecommunications:
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Enhance customer engagement and streamline billing and
                        support operations with our targeted telecom BPO
                        solutions.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Manufacturing
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Improve production efficiency and manage your supply
                        chain with our comprehensive BPO services for the
                        manufacturing industry.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-6 col-lg-6 col-md-6 col-sm-6 wow fadeInRight"
              data-wow-delay="0.4s"
            >
              <img
                src={Big14}
                alt="AG Logo"
                className="dark:invert img-fluid rounded w-100"
              />
            </div>
          </div>
        </div>
      </div>

      <WhyChoose bgLight={false} />
    </>
  );
};
export default Services;
